.container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	/*visibility: hidden;*/
	background-color: rgba(0, 0, 0, 0.4);
}

.container[data-show='true'] {
	visibility: visible;
}

@keyframes show {
	from {
		opacity: 0;
		transform: scale(0.5, 0.5);
	}
	to {
		opacity: 1;
		transform: scale(1, 1);
	}
}

.card {
	z-index: 10;
	padding: 0 !important;
	max-width: calc(100% - 40px);
	animation: show 200ms ease-in-out;
}

.header {
	padding: 16px;
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.header .iconClose {
	width: 48px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	cursor: pointer;
}

.header .iconClose:hover {
	background-color: var(--background-item-hover-2);
}

.header .iconClose svg {
	cursor: pointer;
}
